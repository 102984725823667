/**
 * External Imports
 */
import {
  FC,
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
  MouseEvent
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  NeuIcon,
  NeuButton,
  NeuCardTitle,
  NeuRadio,
  NeuOption,
  NeuDivider
} from '@neutron/react';
import {
  NeuInputChangeEventDetail,
  NeuRadioGroupChangeEventDetail
} from '@neutron/core';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

/**
 * Internal Imports
 */
import RoundingTypeDropdown from '../../customDropdowns/roundingTypeDropdown';
import { BlueChip } from '../../shared/chips/blueChip';
import DeleteBtn from '../../shared/buttons/DeleteBtn';
import { Dropdown } from '../../shared/dropdowns';
import EmployeeSearch from '../reusableModalPieces/employeeSearch';
import FeedbackModalView from './FeedbackModalView';
import PatientSearch from '../reusableModalPieces/patientSearch';
import { RootState } from '../../../redux/store';
import {
  clearEmployeeSearch,
  searchEmployeesToShare
} from '../../../redux/actions/Employee.action';
import { clearPatientSearch } from '../../../redux/actions/Report.action';
import {
  setPendingFeedback,
  getRound,
  clearRoundState
} from '../../../redux/actions/Round.action';
import {
  postNewTask,
  resetSelectedDataForEdit,
  getEmployeeSharedWith,
  resetEmployeeSharedWith,
  completeTask,
  addTaskToSharingQ,
  getCategories
} from '../../../redux/actions/Task.action';
import {
  setSelectedPatient,
  getPatientsToRound,
  clearPatientsToRound,
  setSelectedEmployee
} from '../../../redux/actions/User.action';
import { getTemplateMaster } from '../../../redux/actions/Template.action';

import { logAdHocClick, logTaskClick } from '../../../utils/analyticsHelpers';
import { useDebounceValue } from '../../../utils/debouncers';
import {
  abbreviatedName,
  createAnyId,
  date,
  formatRoundingType,
  toTitleCase
} from '../../../utils/helpers';
/**
 * Global Type Definition Imports
 */
import {
  AuthUserProps,
  Category,
  Employee,
  Feedback,
  FeedbackPost,
  Patient,
  Unit
} from '../../../config/interfaces';
/**
 * Style Imports
 */
import {
  FeedbackCard,
  CardHeader,
  ModalCardContent,
  InputHeading,
  RadioContainer,
  RadioLabel,
  ModalInput,
  ModalTextArea,
  DropDownSearchContainer,
  DropItem,
  ModalFooter,
  CheckIcon,
  MarkCompleteButton,
  TitleSpan,
  TitleIcon,
  FeedbackViewDiv,
  FeedbackViewSubDiv,
  ChipWrapper,
  FeedbackViewDiv2Column,
  CategoryRadioGroup,
  LabelContentBold,
  LabelContentPatient
} from './FeedbackModal.styles';
import { handleModal } from '../../../services/ModalPortal/utils';
import { useUserRolesRef } from '../../../services/UserRoles';

interface IAddFeedbackModalProps {
  authorizedUser: AuthUserProps;
  associatedPatient: {}[];
  categories: [];
  employeeSharedWith?: EmployeeSharedWithProps[];
  feedbackTypes: string[];
  globalUserSection: string;
  isRoundStarted: boolean;
  modalAction: string;
  modalData: Feedback;
  modalType: string;
  roundingTypes: string[];
  searchedEmployees: Employee[];
  searchedEmployeesToShare: [];
  selectedFeedback: FeedbackPost;
  units: Unit[];
}
export interface EmployeeSharedWithProps {
  firstName: string;
  lastName: string;
  employeeId: string;
  fullName: string;
}

type RequiredItemTypes = {
  [key: string]: string | string[] | undefined;
};

const requiredItems: RequiredItemTypes = {
  taskSubtype: '',
  category: '',
  employeeFirstName: '',
  description: ''
};

const AddFeedbackModal: FC<IAddFeedbackModalProps> = ({
  associatedPatient,
  authorizedUser,
  categories,
  employeeSharedWith,
  feedbackTypes,
  globalUserSection,
  isRoundStarted,
  modalAction,
  modalData,
  modalType,
  roundingTypes,
  searchedEmployees,
  searchedEmployeesToShare,
  selectedFeedback,
  units
}) => {
  const dispatch = useDispatch();

  const [sharedSearchTerm, setSharedSearchTerm] = useState('');
  const [sharedPopState, setSharedPopState] = useState<boolean>(false);
  const [sharedSearchState, setSharedSearchState] = useState<boolean>(true);

  const [sharedWithDisplay, setSharedWithDisplay] = useState<Array<string>>([]);
  const [shareObject, setShareObject] = useState<{
    taskId: string;
    userName: string;
    email: string[];
  }>({ taskId: '', userName: '', email: [] });

  const [selectedTaskSubtype, setSelectedTaskSubtype] = useState<string>('');
  const [selectedSubCategoryItem, setSelectedSubCategoryItem] = useState('');
  const [subCategories, setSubCategories] = useState<string[]>([]);

  const [requiredFields, setRequiredFields] = useState(requiredItems);

  const [showButton, setShowButton] = useState<boolean>(false);
  const [openRoundingType, setOpenRoundingType] = useState(false);
  const [openUnit, setOpenUnit] = useState(false);
  const [openCat, setOpenCat] = useState(false);
  const [openSubcat, setOpenSubcat] = useState(false);

  const modalContentRef = useRef<HTMLNeuCardElement>(null);

  const [taskObject, setTaskObject] = useState<FeedbackPost>({
    taskId: createAnyId('task', authorizedUser.facilityId),
    taskType: 'feedback',
    taskSubtype: '',
    completed: false,
    taskDate: date.now(),
    description: '',
    facilityId: authorizedUser.facilityId,
    roundingType: feedbackTypes.includes(globalUserSection)
      ? globalUserSection
      : '',
    sharedWith: [],
    userId: authorizedUser.hcaid,
    userFullName:
      authorizedUser.firstName && authorizedUser.lastName
        ? `${authorizedUser.firstName} ${authorizedUser.lastName}`
        : '',
    _source: 'web',
    taskCategory: [],
    employeeId: '',
    category: '',
    subCategory: '',
    email: ''
  });

  // debouncing search terms
  const dbSharedSearchTerm = useDebounceValue(sharedSearchTerm, 250);

  const { logTrackingEvent } = useAnalyticsApi();
  const {
    isCNEdRounder,
    isEnterpriseAdmin,
    isAllSupportRounder,
    isMultipleRounders,
    isNonSupportRounder,
    isSupportRounder
  } = useUserRolesRef();
  const navigate = useNavigate();

  /** Employee search */
  const userAddedSharedWithEmpId = useRef<string[]>([]);

  const unitName = useMemo(() => {
    const foundUnit: Unit[] = units.filter(
      (unit: Unit) => unit.unitId === taskObject.unitId
    );
    return foundUnit[0]?.unit;
  }, [taskObject, units]);

  const roundingTypesForAdHoc = useMemo(() => {
    let choices = [];
    if (isCNEdRounder && isNonSupportRounder) {
      choices = [...feedbackTypes];
      return choices;
    }
    if (isAllSupportRounder || (!isMultipleRounders && isSupportRounder)) {
      choices.push('cned');
      return choices;
    }
    if (isNonSupportRounder) {
      choices.push('employee', 'patient');
    }
    return choices;
  }, [feedbackTypes]);

  const setRequiredFieldValues = (taskObj: FeedbackPost) => {
    const {
      unitId,
      taskSubtype,
      category,
      subCategory,
      employeeFirstName,
      description
    } = taskObj;
    if (taskObject.roundingType === 'patient') {
      if (subCategories.length > 0) {
        setRequiredFields({
          unitId,
          taskSubtype: taskSubtype || selectedTaskSubtype,
          category,
          subCategory: selectedSubCategoryItem || subCategory,
          description,
          employeeFirstName
        });
      } else {
        setRequiredFields({
          unitId,
          taskSubtype: taskSubtype || selectedTaskSubtype,
          category,
          employeeFirstName,
          description
        });
      }
    } else if (subCategories.length > 0) {
      setRequiredFields({
        taskSubtype: taskSubtype || selectedTaskSubtype,
        category,
        subCategory: selectedSubCategoryItem || subCategory,
        description,
        employeeFirstName
      });
    } else {
      setRequiredFields({
        taskSubtype: taskSubtype || selectedTaskSubtype,
        category,
        employeeFirstName,
        description
      });
    }
  };

  const clearSelectedUser = () => {
    if (
      (modalAction === 'ADD' ||
        modalAction === 'UNIVERSAL-ADD' ||
        modalType === 'Add Patient Feedback On Rounds' ||
        modalType === 'Add Employee Feedback On Rounds' ||
        modalType === 'Add Support Feedback On Rounds') &&
      requiredFields.employeeFirstName
    ) {
      if (taskObject.employeeId !== '') {
        const tempShare: string[] = taskObject.sharedWith;
        setTaskObject({
          ...taskObject,
          sharedWith: tempShare.filter(
            emp => emp !== taskObject.employeeId.toUpperCase()
          ),
          employeeFirstName: '',
          employeeLastName: '',
          employeeId: '',
          employeeFullName: '',
          employeePosition: ''
        });
        setSharedWithDisplay(
          sharedWithDisplay.filter(
            emp =>
              emp.substring(emp.length - 7) !==
              taskObject.employeeId.toUpperCase()
          )
        );
        const tempShareEmail = shareObject.email;
        setShareObject({
          taskId: taskObject.taskId,
          userName: `${authorizedUser.firstName} ${authorizedUser.lastName}`,
          email: tempShareEmail.filter(
            (email: string) => taskObject.email !== email
          )
        });
      } else {
        setTaskObject({
          ...taskObject,
          employeeFirstName: '',
          employeeLastName: '',
          employeeId: '',
          employeeFullName: '',
          employeePosition: ''
        });
      }
      setRequiredFields({
        ...requiredFields,
        employeeFirstName: ''
      });
    }
  };

  const handleSearching = (e: CustomEvent<NeuInputChangeEventDetail>) => {
    const target = e.target as HTMLInputElement;
    const { name } = target;
    if (name === 'sharedWith') {
      setSharedSearchState(true);
      setSharedSearchTerm(target.value);
    }
  };

  const changeFeedbackOrigin = (type: string) => {
    setTaskObject({
      ...taskObject,
      roundingType: type === 'csc' ? 'csrn' : type,
      category: ''
    });
  };

  const handleInputs = (
    event:
      | MouseEvent<HTMLNeuRadioGroupElement>
      | CustomEvent<NeuRadioGroupChangeEventDetail>
  ) => {
    const target = event.target as HTMLNeuInputElement;
    const { name } = target;
    const value = target.value as string;

    if (name === 'taskSubtype') {
      setSelectedTaskSubtype(value);
      if (value === 'recognition') {
        const duplicateFlag =
          taskObject.sharedWith.includes(taskObject.employeeId.toUpperCase()) ||
          taskObject.sharedWith.includes(taskObject.employeeId.toString());
        if (taskObject.employeeId !== '' && !duplicateFlag) {
          setTaskObject({
            ...taskObject,
            category: '',
            subCategory: '',
            [name]: value,
            sharedWith: taskObject.sharedWith
              ? [
                  ...taskObject.sharedWith,
                  `${taskObject.employeeId.toUpperCase()}`
                ]
              : [`${taskObject.employeeId.toUpperCase()}`]
          });
          setSharedWithDisplay([
            ...sharedWithDisplay,
            `${taskObject.employeeFirstName} ${
              taskObject.employeeLastName
            } ${taskObject.employeeId.toUpperCase()}`
          ]);
          setShareObject({
            email: taskObject.email
              ? [...shareObject.email, taskObject.email]
              : [...shareObject.email],
            taskId: taskObject.taskId,
            userName: `${authorizedUser.firstName} ${authorizedUser.lastName}`
          });
        } else if (taskObject.category === '') {
          setTaskObject({ ...taskObject, [name]: value });
        } else {
          setTaskObject({
            ...taskObject,
            category: '',
            subCategory: '',
            [name]: value
          });
        }
      } else if (
        taskObject.employeeId !== '' &&
        !userAddedSharedWithEmpId.current.includes(taskObject.employeeId)
      ) {
        const tempShared = taskObject.sharedWith;
        setTaskObject({
          ...taskObject,
          category: '',
          subCategory: '',
          [name]: value,
          sharedWith: tempShared.filter(
            emp => emp !== taskObject.employeeId.toString()
          )
        });
        setSharedWithDisplay(
          sharedWithDisplay.filter(
            emp =>
              emp.substring(emp.length - 7) !==
              taskObject.employeeId.toUpperCase()
          )
        );
        setShareObject({
          email: shareObject.email.filter(
            (email: string) => taskObject.email !== email
          ),
          taskId: taskObject.taskId,
          userName: `${authorizedUser.firstName} ${authorizedUser.lastName}`
        });
      } else if (taskObject.category === '') {
        setTaskObject({ ...taskObject, [name]: value });
      } else {
        setTaskObject({
          ...taskObject,
          category: '',
          subCategory: '',
          [name]: value
        });
      }
      setRequiredFieldValues({
        ...taskObject,
        category: '',
        subCategory: ''
      });
    }

    if (name === 'roundingType') {
      setTaskObject({ ...taskObject, roundingType: value });
    }
    if (name === 'description') {
      setTaskObject({ ...taskObject, [name]: value });
    }
    // For validation
    if (Object.keys(requiredFields).includes(name)) {
      setRequiredFields({ ...requiredFields, [name]: value });
    }
  };

  const handleDropdownChange = useCallback(
    (value: string, name: string) => {
      if (name === 'category') {
        setTaskObject({ ...taskObject, [name]: value, subCategory: '' });
      } else {
        setTaskObject({ ...taskObject, [name]: value });
      }
      if (name === 'subCategory') {
        setSelectedSubCategoryItem(value);
      }

      // For validation
      if (Object.keys(requiredFields).includes(name)) {
        setRequiredFields({ ...requiredFields, [name]: value });
      }
    },
    [requiredFields, taskObject]
  );
  const setPatientForFeedback = (patient: Patient) => {
    setTaskObject({
      ...taskObject,
      room: patient.room,
      bed: patient.bed,
      accountNum: patient.accountNumber as string,
      patientFirstName: patient.firstname as string,
      patientLastName: patient.lastname as string,
      patientFullName: `${patient.lastname}, ${patient.firstname}`
    });
  };

  const setEmployeeToShare = (emp: Employee) => {
    const empId = emp.employeeId.toUpperCase();
    if (!userAddedSharedWithEmpId.current.includes(empId)) {
      userAddedSharedWithEmpId.current.push(empId);
    }
    const empExists = taskObject.sharedWith.includes(empId);
    if (!empExists) {
      setTaskObject({
        ...taskObject,
        sharedWith: taskObject.sharedWith
          ? [...taskObject.sharedWith, `${empId}`]
          : [`${empId}`]
      });
      setSharedWithDisplay([
        ...sharedWithDisplay,
        `${emp.firstName} ${emp.lastName} ${empId}`
      ]);
      setShareObject({
        email: [...shareObject.email, emp.email],
        taskId: taskObject.taskId,
        userName: `${authorizedUser.firstName} ${authorizedUser.lastName}`
      });
    } else {
      // TODO future development add a toast here saying 'user already selected'
      console.log('User already selected');
    }
    setSharedSearchState(false);
    setSharedSearchTerm('');
    setSharedPopState(false);
    dispatch(clearEmployeeSearch());
  };

  const setEmployeeForFeedback = (emp: Employee) => {
    const empId = emp.employeeId.toUpperCase();
    if (taskObject.taskSubtype === 'recognition') {
      const duplicateFlag = taskObject.sharedWith.includes(empId);
      if (!duplicateFlag) {
        setTaskObject({
          ...taskObject,
          employeeFirstName: emp.firstName,
          employeeLastName: emp.lastName,
          employeeId: empId,
          employeeFullName: `${emp.firstName} ${emp.lastName}`,
          employeePosition: emp.title,
          email: emp.email,
          sharedWith: taskObject.sharedWith
            ? [...taskObject.sharedWith, `${empId}`]
            : [`${empId}`],
          department: emp.dept,
          departmentCode: emp.departmentCode,
          departmentId: emp.departmentId
        });
        setSharedWithDisplay([
          ...sharedWithDisplay,
          `${emp.firstName} ${emp.lastName} ${empId}`
        ]);
        setShareObject({
          email: [...shareObject.email, emp.email],
          taskId: taskObject.taskId,
          userName: `${authorizedUser.firstName} ${authorizedUser.lastName}`
        });
      } else {
        setTaskObject({
          ...taskObject,
          employeeFirstName: emp.firstName,
          employeeLastName: emp.lastName,
          employeeId: emp.employeeId,
          employeeFullName: `${emp.firstName} ${emp.lastName}`,
          employeePosition: emp.title,
          email: emp.email,
          department: emp.dept,
          departmentCode: emp.departmentCode,
          departmentId: emp.departmentId
        });
      }
    } else {
      setTaskObject({
        ...taskObject,
        employeeFirstName: emp.firstName,
        employeeLastName: emp.lastName,
        employeeId: empId,
        employeeFullName: `${emp.firstName} ${emp.lastName}`,
        employeePosition: emp.title,
        email: emp.email,
        department: emp.dept,
        departmentCode: emp.departmentCode,
        departmentId: emp.departmentId
      });
    }
    // For validation
    setRequiredFields({
      ...requiredFields,
      employeeFirstName: emp.firstName
    });
  };

  const handleClose = () => {
    setTaskObject({
      taskId: '',
      taskType: 'feedback',
      taskSubtype: '',
      completed: false,
      taskDate: '',
      description: '',
      facilityId: '',
      roundingType: '',
      sharedWith: [],
      userId: '',
      userFullName: '',
      _source: 'web',
      category: '',
      subCategory: '',
      taskCategory: [],
      employeeId: '',
      email: ''
    });
    setShareObject({ taskId: '', userName: '', email: [] });
    dispatch(clearPatientSearch());
    dispatch(clearEmployeeSearch());
    setSharedWithDisplay([]);
    dispatch(resetSelectedDataForEdit());
    dispatch(resetEmployeeSharedWith());
    handleModal(modalContentRef);
  };

  const handlePost = () => {
    if (showButton) {
      if (modalAction === 'UNIVERSAL-ADD') {
        logTrackingEvent(logAdHocClick(modalType, 'save'));
      } else {
        logTrackingEvent(logTaskClick('feedback', 'save'));
      }
      const payload = { ...taskObject };
      delete payload.email;
      if (isRoundStarted && modalData) {
        dispatch(setPendingFeedback(payload));
      } else {
        dispatch(postNewTask(payload));
      }
      if (
        shareObject.taskId &&
        shareObject.userName &&
        shareObject.email.length > 0
      ) {
        dispatch(addTaskToSharingQ(shareObject));
      }
      setTimeout(() => {
        handleClose();
      }, 500);
    }
  };

  const handleMarkCompleted = () => {
    const postData = {
      taskId: selectedFeedback.taskId,
      edit: true,
      taskType: 'feedback'
    };
    dispatch(completeTask(postData));
    handleClose();
    // appInsights.trackEvent({
    //   name: 'Feedback_Completed'
    // });
  };

  const handleRemoveSharedWith = (emp: string) => {
    let removedEmpName: string;
    const spaces = emp.split(' ').length - 1;
    if (spaces === 2) {
      removedEmpName = emp.split(' ').slice(0, 2).join('.');
    } else {
      const splittedArray = emp.split(' ');
      removedEmpName = `${splittedArray[0] + splittedArray[1]}.${
        splittedArray[2]
      }`;
    }
    const removedEmpId = emp.split(/[\s+]+/).pop() || '';
    userAddedSharedWithEmpId.current = userAddedSharedWithEmpId.current.filter(
      (empId: string) => empId !== removedEmpId
    );
    if (taskObject.taskSubtype === 'recognition') {
      if (removedEmpId?.trim() !== taskObject.employeeId.toUpperCase()) {
        // Update sharedWith in task object
        const newSharedWith = taskObject.sharedWith.filter((item: string) => {
          return item.includes(removedEmpId) === false;
        });
        setTaskObject({
          ...taskObject,
          sharedWith: newSharedWith
        });

        // update Emails for sharing
        const emailsAfterRemove = shareObject.email.filter((email: string) => {
          const existingString = email.split('@')[0];
          return existingString !== removedEmpName;
        });
        setShareObject({ ...shareObject, email: emailsAfterRemove });
      }
    } else {
      // Update sharedWith in task object
      const newSharedWith = taskObject.sharedWith.filter((item: string) => {
        return item.includes(removedEmpId) === false;
      });
      setTaskObject({
        ...taskObject,
        sharedWith: newSharedWith
      });

      // update Emails for sharing
      const emailsAfterRemove = shareObject.email.filter((email: string) => {
        const existingString = email.split('@')[0];
        return existingString !== removedEmpName;
      });
      setShareObject({ ...shareObject, email: emailsAfterRemove });
    }
  };

  const handleRedirect = () => {
    if (taskObject?.roundId) {
      if (globalUserSection === 'patient') {
        if (associatedPatient && associatedPatient.length === 1) {
          dispatch(clearPatientsToRound());
          handleModal(modalContentRef);
          if (taskObject?.accountNum?.substring(0, 2) === 'UL') {
            navigate('/patients/profile', {
              state: { isUnlistedPatient: true },
              replace: true
            });
          } else {
            navigate('/patients/profile', {
              state: { isUnlistedPatient: false },
              replace: true
            });
          }
        }
      }
      if (globalUserSection === 'employee') {
        handleModal(modalContentRef);
        navigate('/employees/profile');
      }
      if (globalUserSection === 'csc' || globalUserSection === 'cned') {
        handleModal(modalContentRef);
        navigate(`/support/profile/${globalUserSection}`);
      }
    }
  };
  /* Code block for navigating to round from issue modal */

  const handleRemoveChip = (type: string) => {
    if (type === 'patient') {
      setTaskObject({
        ...taskObject,
        room: '',
        accountNum: '',
        patientFirstName: '',
        patientLastName: '',
        patientFullName: ''
      });
    } else {
      clearSelectedUser();
      setTaskObject({
        ...taskObject,
        employeeFirstName: '',
        employeeLastName: '',
        employeeId: '',
        employeeFullName: '',
        employeePosition: '',
        employee: '',
        department: '',
        departmentCode: '',
        departmentId: ''
      });
      setRequiredFields({
        ...requiredFields,
        employeeFirstName: ''
      });
    }
  };

  // Clear data when unmount
  useEffect(() => {
    return () => {
      handleClose();
    };
  }, []);

  /** Shared with - search */
  useEffect(() => {
    if (sharedSearchTerm.length > 2) {
      dispatch(searchEmployeesToShare(dbSharedSearchTerm));
    }
  }, [dbSharedSearchTerm]);

  useEffect(() => {
    if (searchedEmployeesToShare.length > 0 && sharedSearchTerm.length > 0) {
      setSharedPopState(true);
    }
  }, [searchedEmployeesToShare]);

  useEffect(() => {
    const selectedCat: Category = categories.filter((category: Category) => {
      return category.name === taskObject.category;
    })[0];
    if (selectedCat) {
      setSubCategories(selectedCat.subCategories);
    } else {
      setSubCategories([]);
    }
  }, [taskObject.category, categories]);

  useEffect(() => {
    setRequiredFieldValues(taskObject);
  }, [taskObject.roundingType, taskObject.category, subCategories]);

  useEffect(() => {
    if (isRoundStarted && modalData) {
      setTaskObject({
        ...taskObject,
        ...(globalUserSection === 'patient'
          ? {
              accountNum: modalData.accountNum || (modalData.mrn as string),
              patientFirstName: (modalData.firstName as string) || '',
              patientLastName: (modalData.lastName as string) || '',
              patientFullName:
                modalData.firstName && modalData.lastName
                  ? `${modalData.firstName} ${modalData.lastName}`
                  : '',
              room: modalData.room,
              bed: modalData.bed || '',
              unit:
                units.find(unit => unit.unitId === modalData.unitId)?.unit ||
                '',
              unitId: modalData.unitId
            }
          : {}),
        ...(globalUserSection === 'employee' ||
        globalUserSection === 'csc' ||
        globalUserSection === 'cned'
          ? {
              employeeFirstName: (modalData.firstName as string) || '',
              employeeLastName: (modalData.lastName as string) || '',
              employeeFullName: modalData.firstName
                ? `${modalData.lastName}, ${modalData.firstName}`
                : '',
              employeeId: modalData.hcaid as string,
              ...(searchedEmployees && searchedEmployees.length === 1
                ? {
                    department: searchedEmployees[0].department,
                    departmentCode: searchedEmployees[0].departmentCode,
                    departmentId: searchedEmployees[0].departmentId
                  }
                : {})
            }
          : {})
      });
      setRequiredFields(
        globalUserSection === 'patient'
          ? { ...requiredFields, unitId: modalData.unitId }
          : {
              ...requiredFields,
              employeeFirstName: modalData.employeeFirstName
            }
      );
    } else {
      setTaskObject({
        ...taskObject
      });
    }
  }, []);

  useEffect(() => {
    if (
      (modalAction === 'EDIT' || modalAction === 'PARTIAL-EDIT') &&
      selectedFeedback
    ) {
      /**
       * In order to add sharedWith property to the feedback object if its not present in the
       * selected feedback for edit/partial edit.
       */
      const tempFeedback = selectedFeedback.sharedWith
        ? { ...selectedFeedback }
        : { ...selectedFeedback, sharedWith: [] };
      setTaskObject({ ...tempFeedback });
      // Get employee details in order to show on chips if the sharedWith array is not empty.
      if (
        selectedFeedback.sharedWith &&
        selectedFeedback.sharedWith.length > 0
      ) {
        selectedFeedback.sharedWith.forEach(employeeId => {
          dispatch(getEmployeeSharedWith(employeeId));
        });
      }
      setSelectedTaskSubtype(selectedFeedback.taskSubtype);
      setRequiredFieldValues(selectedFeedback);
    }
  }, [selectedFeedback, modalAction]);

  useEffect(() => {
    if (employeeSharedWith && employeeSharedWith.length > 0) {
      employeeSharedWith.forEach((item: EmployeeSharedWithProps) => {
        setSharedWithDisplay([
          ...sharedWithDisplay,
          `${item.firstName} ${item.lastName} ${item.employeeId}`
        ]);
      });
    }
  }, [employeeSharedWith]);

  // For updating the feedback category list based on the selection
  useEffect(() => {
    if (
      taskObject.roundingType &&
      roundingTypes.includes(taskObject.roundingType) &&
      (selectedTaskSubtype || taskObject.taskSubtype)
    ) {
      let categoryFor;
      switch (taskObject.roundingType) {
        case 'patient':
          categoryFor = 'patient';
          break;
        default:
          categoryFor = 'employee';
          break;
      }
      dispatch(
        getCategories({
          categoryFor,
          categoryType: 'feedback',
          feedbackType: selectedTaskSubtype || taskObject.taskSubtype
        })
      );
    }
  }, [selectedTaskSubtype, taskObject.taskSubtype, taskObject.roundingType]);

  // Validate fields on change and disable button
  useEffect(() => {
    let count = 0;
    Object.keys(requiredFields).forEach(item => {
      const tempVar = requiredFields[item];
      if (
        tempVar === '' ||
        tempVar === undefined ||
        (Array.isArray(tempVar) && tempVar.length === 0)
      ) {
        count += 1;
      }
      if (count === 0) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, [requiredFields]);

  useEffect(() => {
    if (selectedFeedback) {
      if (selectedFeedback?.roundId) {
        dispatch(getTemplateMaster({ condensed: true, archived: false }));
        dispatch(
          getRound({
            roundId: selectedFeedback?.roundId,
            fromHistorydrawer: false
          })
        );
      } else if (
        !selectedFeedback?.roundId &&
        !(modalAction === 'UNIVERSAL-ADD' && modalType === 'Feedback')
      ) {
        dispatch(clearRoundState());
      }
    }
  }, [selectedFeedback]);

  /* Code block for navigating to round from issue modal */
  useEffect(() => {
    if (
      taskObject &&
      taskObject?.roundId &&
      (modalAction === 'VIEW' ||
        modalAction === 'EDIT' ||
        modalAction === 'PARTIAL-EDIT')
    ) {
      if (
        globalUserSection === 'patient' &&
        taskObject?.facilityId &&
        taskObject?.accountNum
      ) {
        dispatch(
          getPatientsToRound({
            facilityId: taskObject.facilityId,
            accountNum: taskObject.accountNum
          })
        );
      }

      if (
        (globalUserSection === 'employee' ||
          globalUserSection === 'csc' ||
          globalUserSection === 'cned') &&
        taskObject?.employeeId
      ) {
        dispatch(
          setSelectedEmployee({
            hcaid: taskObject.employeeId.toLowerCase(),
            firstName: taskObject.employeeFirstName,
            lastName: taskObject.employeeLastName
          })
        );
      }

      if (taskObject?.roundId) {
        dispatch(getTemplateMaster({ condensed: true, archived: false }));
        dispatch(
          getRound({
            roundId: taskObject.roundId,
            fromHistorydrawer: false
          })
        );
      }
    }
  }, []);

  useEffect(() => {
    if (
      (modalAction === 'VIEW' ||
        modalAction === 'EDIT' ||
        modalAction === 'PARTIAL-EDIT') &&
      associatedPatient &&
      associatedPatient.length === 1
    ) {
      dispatch(setSelectedPatient(associatedPatient[0]));
    }
  }, [associatedPatient]);

  return (
    <FeedbackCard
      type="submit"
      height="100vh"
      minHeight="1rem"
      id="Feedback-Modal-Card"
      className="show-modal"
      ref={modalContentRef}
    >
      {modalAction === 'VIEW' && selectedFeedback && (
        <FeedbackModalView
          feedbackInfo={selectedFeedback}
          employeeSharedWith={employeeSharedWith}
          onClose={handleClose}
          userSection={globalUserSection}
          units={units}
          associatedPatient={associatedPatient}
          handleRedirect={handleRedirect}
        />
      )}
      {modalAction !== 'VIEW' && (
        <>
          <CardHeader>
            <NeuCardTitle>
              {(modalAction === 'ADD' ||
                modalAction === 'UNIVERSAL-ADD' ||
                (isRoundStarted && !selectedFeedback)) && (
                <TitleSpan>
                  <TitleIcon large>campaign</TitleIcon>
                  Add Feedback
                </TitleSpan>
              )}
              {(modalAction === 'EDIT' || modalAction === 'PARTIAL-EDIT') &&
                selectedFeedback &&
                !selectedFeedback.completed && (
                  // TODO: This needs to go away in favor of shared button
                  <MarkCompleteButton
                    onClick={() => handleMarkCompleted()}
                    fill="outline"
                    color="success"
                  >
                    <CheckIcon large>check_circle</CheckIcon>
                    Mark Complete
                  </MarkCompleteButton>
                )}
            </NeuCardTitle>
            <NeuButton
              fill="flat"
              onClick={() => {
                if (modalAction === 'UNIVERSAL-ADD') {
                  logTrackingEvent(logAdHocClick(modalType, 'close'));
                } else {
                  logTrackingEvent(logTaskClick('feedback', 'close'));
                }
                handleClose();
              }}
            >
              <NeuIcon large color="primary">
                close
              </NeuIcon>
              Close
            </NeuButton>
          </CardHeader>
          <ModalCardContent>
            {modalAction === 'UNIVERSAL-ADD' && (
              <>
                <InputHeading>Issue Origin</InputHeading>
                <RoundingTypeDropdown
                  name="feedback-rounding-type"
                  open={openRoundingType}
                  options={roundingTypesForAdHoc}
                  placeholder={
                    taskObject?.roundingType &&
                    roundingTypesForAdHoc.includes(taskObject?.roundingType)
                      ? formatRoundingType(taskObject?.roundingType)
                      : 'Select Origin'
                  }
                  selectedRoundingType={taskObject.roundingType ?? ''}
                  setSelectedRoundingType={changeFeedbackOrigin}
                  setOpen={setOpenRoundingType}
                />
              </>
            )}
            {modalAction === 'ADD' || modalAction === 'UNIVERSAL-ADD' ? (
              <>
                {taskObject.roundingType === 'patient' && (
                  <>
                    <InputHeading>Patient (Optional)</InputHeading>
                    <PatientSearch
                      propObject={taskObject}
                      chipVal={taskObject.patientFullName}
                      setPatientSelection={(patient: Patient) =>
                        setPatientForFeedback(patient)
                      }
                      handleRemovePatient={handleRemoveChip}
                      showLabel={false}
                    />
                  </>
                )}
                {(taskObject.roundingType === 'employee' ||
                  taskObject.roundingType === 'csc' ||
                  taskObject.roundingType === 'cned') && (
                  <>
                    <InputHeading>Employee</InputHeading>
                    <EmployeeSearch
                      val={taskObject}
                      chipVal={taskObject.employeeFullName}
                      setEmployeeSelection={(emp: Employee) =>
                        setEmployeeForFeedback(emp)
                      }
                      handleRemoveEmployee={handleRemoveChip}
                      showLabel={false}
                    />
                  </>
                )}
                {taskObject.roundingType === 'patient' && (
                  <>
                    <InputHeading>Unit</InputHeading>
                    <Dropdown
                      classes="pl-0 pr-2"
                      name="unitId"
                      open={openUnit}
                      placeholder={
                        units.find(
                          (unit: Unit) => unit.unitId === taskObject.unitId
                        )?.unit || 'Select a Unit'
                      }
                      width="97%"
                      setOpen={setOpenUnit}
                    >
                      {units &&
                        units.map((unit: Unit) => (
                          <NeuOption
                            className={`dropdown-hover-item${
                              taskObject.unitId === unit.unitId
                                ? ' selected'
                                : ''
                            }`}
                            value={unit.unitId}
                            key={unit.unitId}
                            onClick={() =>
                              handleDropdownChange(unit.unitId, 'unitId')
                            }
                          >
                            {unit.unit}
                          </NeuOption>
                        ))}
                    </Dropdown>
                  </>
                )}
              </>
            ) : (
              <>
                <FeedbackViewDiv2Column>
                  <FeedbackViewSubDiv
                    style={
                      taskObject.roundingType === 'employee' ||
                      taskObject.roundingType === 'csc' ||
                      taskObject.roundingType === 'cned'
                        ? { width: '100%' }
                        : {}
                    }
                  >
                    {taskObject.roundingType === 'patient' ? (
                      <>
                        <InputHeading>Patient</InputHeading>
                        <LabelContentPatient
                          color={
                            taskObject &&
                            taskObject.roundId &&
                            associatedPatient.length === 1
                              ? '#206DA3'
                              : '#191919'
                          }
                          cursorType={
                            !!(
                              taskObject &&
                              taskObject.roundId &&
                              associatedPatient.length === 1
                            )
                          }
                          onClick={() => {
                            handleRedirect();
                          }}
                        >
                          {!taskObject.patientLastName ||
                          !taskObject.patientFirstName
                            ? 'Unknown'
                            : abbreviatedName(
                                taskObject.patientFirstName,
                                taskObject.patientLastName
                              )}
                        </LabelContentPatient>
                      </>
                    ) : !(
                        modalType === 'Add Employee Feedback On Rounds' ||
                        modalType === 'Add Support Feedback On Rounds'
                      ) ? (
                      <>
                        <InputHeading>Employee</InputHeading>
                        <LabelContentPatient
                          color="#191919"
                          // cursorType={
                          //   !!(
                          //     taskObject &&
                          //     taskObject.roundId &&
                          //     taskObject.employeeId
                          //   )
                          // }
                          // onClick={() => {
                          // handleRedirect();
                          // }}
                        >
                          {`${
                            taskObject.employeeLastName
                              ? `${toTitleCase(taskObject.employeeLastName)}, `
                              : ''
                          }${
                            taskObject.employeeFirstName
                              ? toTitleCase(taskObject.employeeFirstName)
                              : ''
                          }`}
                          {`${
                            taskObject.employeeId
                              ? ` | ${taskObject.employeeId}`
                              : 'Unknown'
                          }`}
                          {`${
                            !taskObject.employeeLastName &&
                            !taskObject.employeeFirstName
                              ? 'Unknown'
                              : ''
                          }`}
                        </LabelContentPatient>
                      </>
                    ) : (
                      <>
                        <InputHeading>Employee</InputHeading>
                        <EmployeeSearch
                          val={taskObject}
                          chipVal={taskObject.employeeFullName}
                          setEmployeeSelection={(emp: Employee) =>
                            setEmployeeForFeedback(emp)
                          }
                          handleRemoveEmployee={handleRemoveChip}
                          showLabel={false}
                        />
                      </>
                    )}
                  </FeedbackViewSubDiv>
                  {taskObject.roundingType === 'patient' && !isRoundStarted && (
                    <FeedbackViewSubDiv>
                      <InputHeading>Room</InputHeading>
                      <LabelContentBold>
                        {taskObject.patientFirstName
                          ? taskObject.room
                            ? `${taskObject.room}${taskObject.bed || ''}`
                            : 'Unknown'
                          : 'N/A'}
                      </LabelContentBold>
                    </FeedbackViewSubDiv>
                  )}
                </FeedbackViewDiv2Column>
                {taskObject.roundingType === 'patient' && !isRoundStarted && (
                  <FeedbackViewDiv>
                    <InputHeading>Unit</InputHeading>
                    <LabelContentBold>
                      {`${taskObject.unitId ? unitName : 'Unknown'}`}
                    </LabelContentBold>
                  </FeedbackViewDiv>
                )}
                {taskObject.roundingType === 'employee' && !isRoundStarted && (
                  <FeedbackViewDiv>
                    <InputHeading>Department</InputHeading>
                    <LabelContentBold>
                      {`${
                        taskObject?.department
                          ? taskObject?.department
                          : 'Unknown'
                      }`}
                    </LabelContentBold>
                  </FeedbackViewDiv>
                )}
                {taskObject.roundingType === 'patient' && !isRoundStarted && (
                  <FeedbackViewDiv>
                    <InputHeading>Employee</InputHeading>
                    {(taskObject.employeeFirstName ||
                      taskObject.employeeLastName) && (
                      <BlueChip
                        key={taskObject.employeeId}
                        name={`${taskObject.employeeFirstName} ${
                          taskObject.employeeLastName
                        } ${
                          taskObject.employeeId
                            ? taskObject.employeeId.toString().toUpperCase()
                            : ''
                        }`}
                        removable={false}
                      />
                    )}
                  </FeedbackViewDiv>
                )}
                {taskObject.roundingType === 'patient' && isRoundStarted && (
                  <>
                    <InputHeading>Unit</InputHeading>
                    <Dropdown
                      classes="pl-0 pr-2"
                      name="unitId"
                      open={openUnit}
                      placeholder={
                        units.find(
                          (unit: Unit) => unit.unitId === taskObject.unitId
                        )?.unit || 'Select a Unit'
                      }
                      width="97%"
                      setOpen={setOpenUnit}
                    >
                      {units &&
                        units.map((unit: Unit) => (
                          <NeuOption
                            className={`dropdown-hover-item${
                              taskObject.unitId === unit.unitId
                                ? ' selected'
                                : ''
                            }`}
                            value={unit.unitId}
                            key={unit.unitId}
                            onClick={() =>
                              handleDropdownChange(unit.unitId, 'unitId')
                            }
                          >
                            {unit.unit}
                          </NeuOption>
                        ))}
                    </Dropdown>
                  </>
                )}
                {!(modalType === 'Add Patient Feedback On Rounds') && (
                  <FeedbackViewSubDiv>
                    <InputHeading>Creator</InputHeading>
                    <LabelContentBold className="my-1">
                      {toTitleCase(taskObject.userFullName)}
                    </LabelContentBold>
                  </FeedbackViewSubDiv>
                )}
              </>
            )}

            {modalAction !== 'PARTIAL-EDIT' ? (
              <>
                <CategoryRadioGroup
                  onClick={handleInputs}
                  name="taskSubtype"
                  value={selectedTaskSubtype || ''}
                >
                  <InputHeading>Feedback Type</InputHeading>
                  <RadioContainer>
                    <NeuRadio name="taskSubtype" value="recognition">
                      Recognition
                    </NeuRadio>
                    <RadioLabel>Recognition</RadioLabel>
                    <NeuRadio name="taskSubtype" value="coaching">
                      Coaching
                    </NeuRadio>
                    <RadioLabel>Coaching</RadioLabel>
                  </RadioContainer>
                </CategoryRadioGroup>
                <InputHeading>Feedback Category</InputHeading>
                {taskObject.taskCategory.length >= 1 ? (
                  <FeedbackViewDiv>
                    {taskObject.taskCategory.map(category => {
                      return (
                        <BlueChip
                          key={category}
                          name={category}
                          removable={false}
                        />
                      );
                    })}
                  </FeedbackViewDiv>
                ) : (
                  <>
                    <Dropdown
                      id="Feedback-Modal-Category-Select"
                      classes="pl-0 pr-2"
                      name="category"
                      open={openCat}
                      placeholder={
                        taskObject.category
                          ? taskObject.category
                          : 'Select a Category'
                      }
                      width="97%"
                      setOpen={setOpenCat}
                      disabled={
                        !taskObject.roundingType || !selectedTaskSubtype
                      }
                    >
                      {categories.map((category: Category) => (
                        <NeuOption
                          key={category.name}
                          className={`dropdown-hover-item${
                            category.name === taskObject.category
                              ? ' selected'
                              : ''
                          }`}
                          value={category.name}
                          onClick={() =>
                            handleDropdownChange(category.name, 'category')
                          }
                        >
                          {category.name}
                        </NeuOption>
                      ))}
                    </Dropdown>
                    <InputHeading>Feedback Subcategory</InputHeading>
                    <Dropdown
                      id="Feedback-Modal-Subcategory-Select"
                      classes="pl-0 pr-2"
                      name="subCategory"
                      open={openSubcat}
                      placeholder={
                        taskObject.subCategory
                          ? taskObject.subCategory
                          : taskObject.category && subCategories.length === 0
                          ? 'No subcategories'
                          : 'Select a Subcategory'
                      }
                      width="97%"
                      setOpen={setOpenSubcat}
                      disabled={
                        !taskObject.category ||
                        (categories.length > 0 && subCategories.length === 0)
                      }
                    >
                      {subCategories.map((subcat: string) => (
                        <NeuOption
                          key={subcat}
                          className={`dropdown-hover-item${
                            subcat === taskObject.subCategory ? ' selected' : ''
                          }`}
                          value={subcat}
                          onClick={() =>
                            handleDropdownChange(subcat, 'subCategory')
                          }
                        >
                          {subcat}
                        </NeuOption>
                      ))}
                    </Dropdown>
                  </>
                )}
              </>
            ) : (
              <>
                <FeedbackViewDiv>
                  <InputHeading>Feedback Category</InputHeading>
                  <LabelContentBold>
                    {`${
                      taskObject.taskSubtype
                        ? toTitleCase(taskObject.taskSubtype)
                        : ''
                    } | `}
                    {taskObject.taskCategory?.join(', ')}
                  </LabelContentBold>
                </FeedbackViewDiv>
                {/* TODO: Add subCategory */}
              </>
            )}
            {(modalAction === 'ADD' ||
              modalAction === 'UNIVERSAL-ADD' ||
              isRoundStarted) &&
              taskObject.roundingType === 'patient' && (
                <>
                  <InputHeading>Employee</InputHeading>
                  <EmployeeSearch
                    val={taskObject}
                    chipVal={taskObject.employeeFullName}
                    setEmployeeSelection={(emp: Employee) =>
                      setEmployeeForFeedback(emp)
                    }
                    handleRemoveEmployee={handleRemoveChip}
                    showLabel={false}
                  />
                </>
              )}

            {modalAction !== 'PARTIAL-EDIT' ? (
              <>
                <InputHeading>Details</InputHeading>
                <ModalTextArea
                  placeholder="Do not include PHI"
                  wrap="hard"
                  inputmode="text"
                  enterkeyhint="next"
                  name="description"
                  required
                  spellCheck
                  value={taskObject.description ? taskObject.description : ''}
                  onNeuChange={handleInputs}
                />
              </>
            ) : (
              <FeedbackViewDiv>
                <InputHeading>Details</InputHeading>
                <LabelContentBold>
                  {taskObject.description ? taskObject.description : 'N/A'}
                </LabelContentBold>
              </FeedbackViewDiv>
            )}
            <InputHeading>Share With (Optional)</InputHeading>
            <div onBlur={() => setSharedPopState(false)}>
              {sharedWithDisplay.length > 0 &&
                sharedWithDisplay.map(emp => {
                  return (
                    <ChipWrapper
                      key={emp}
                      onClick={() => handleRemoveSharedWith(emp)}
                    >
                      {taskObject.taskSubtype === 'recognition' &&
                        emp.split(/[\s+]+/).pop() ===
                          taskObject.employeeId.toUpperCase() && (
                          <BlueChip key={emp} name={emp} removable={false} />
                        )}
                      {taskObject.taskSubtype !== 'recognition' && (
                        <BlueChip key={emp} name={emp} />
                      )}
                      {taskObject.taskSubtype === 'recognition' &&
                        emp.split(/[\s+]+/).pop() !==
                          taskObject.employeeId.toUpperCase() && (
                          <BlueChip key={emp} name={emp} />
                        )}
                    </ChipWrapper>
                  );
                })}
              <div style={{ position: 'relative' }}>
                <ModalInput
                  type="search"
                  name="sharedWith"
                  autocomplete="off"
                  placeholder="Start typing name or 3-4 ID"
                  inputmode="search"
                  enterkeyhint="search"
                  autocorrect="off"
                  value={sharedSearchState ? sharedSearchTerm : null}
                  onNeuChange={sharedSearchState ? handleSearching : () => {}}
                  onNeuFocus={() => setSharedSearchState(true)}
                />
                <DropDownSearchContainer showPopover={sharedPopState}>
                  {searchedEmployeesToShare.map((emp: Employee) => (
                    <div key={emp.employeeId}>
                      <DropItem
                        onMouseDown={() => setEmployeeToShare(emp)}
                        button
                      >
                        <p>
                          {emp.firstName.toUpperCase()}{' '}
                          {emp.lastName.toUpperCase()}
                        </p>
                        <p style={{ fontSize: '14px' }}>{emp.employeeId}</p>
                        <p style={{ fontSize: '14px' }}>{emp.title}</p>
                      </DropItem>
                      {searchedEmployeesToShare.length > 1 && <NeuDivider />}
                    </div>
                  ))}
                </DropDownSearchContainer>
              </div>
            </div>
          </ModalCardContent>
          <ModalFooter small>
            {(modalAction === 'EDIT' || modalAction === 'PARTIAL-EDIT') &&
              selectedFeedback &&
              (selectedFeedback.userId === authorizedUser.hcaid ||
                isEnterpriseAdmin) && <DeleteBtn task={selectedFeedback} />}
            <NeuButton
              color="primary"
              onClick={handlePost}
              style={{ float: 'right' }}
              disabled={!showButton ? true : undefined}
            >
              Save Feedback
            </NeuButton>
          </ModalFooter>
        </>
      )}
    </FeedbackCard>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    associatedPatient: state.UserReducer.patientsToRound,
    authorizedUser: state.AuthorizedUser.authorizedUser,
    categories: state.TaskReducer.categories,
    employeeSharedWith: state.TaskReducer.employeeSharedWith,
    globalUserSection: state.UserReducer.userSection,
    isRoundStarted: state.RoundReducer.isRoundStarted,
    modalAction: state.ModalReducer.modalAction,
    modalData: state.ModalReducer.modalData,
    modalState: state.ModalReducer.modal,
    modalType: state.ModalReducer.modalType,
    roundingTypes: state.ConfigReducer.validRoundingTypes,
    feedbackTypes: state.ConfigReducer.validTaskRoundingTypes,
    searchedEmployees: state.EmployeeReducer.searchedEmployees,
    searchedEmployeesToShare: state.EmployeeReducer.searchedEmpsToShare,
    selectedFeedback: state.TaskReducer.selectedData,
    searchedPatients: state.ReportReducer.searchedPatients,
    units: state.ConfigReducer.units
  };
};

export default connect(mapReduxStateToProps)(AddFeedbackModal);
