import styled from 'styled-components';

export const RoundCloseContainer = styled.div`
  align-items: center;
  min-height: 48px;
  position: relative;
`;

export const CloseBtnContainer = styled.div`
  position: absolute;
  left: 0;
`;
