import { FC, useMemo } from 'react';
import { format, sub } from 'date-fns';

import { NeuButton, NeuButtonToggle, NeuInput, NeuLabel } from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

import { logRoundProfileClick } from '../../../utils/analyticsHelpers';
import { date } from '../../../utils/helpers';

interface RoundDateTimeSelectProps {
  day: string;
  isInvalidTime: boolean;
  isInvalidDateTime: boolean;
  isViewOnly: boolean;
  selectedTime: string;
  selectedDateTime: string;
  type: string;
  handleDateTimeChange: (e: any) => void;
  setDay: (value: string) => void;
}

const RoundDateTimeSelect: FC<RoundDateTimeSelectProps> = ({
  day,
  isInvalidTime,
  isInvalidDateTime,
  isViewOnly,
  selectedTime,
  selectedDateTime,
  type,
  handleDateTimeChange,
  setDay
}) => {
  const { logTrackingEvent } = useAnalyticsApi();

  const maxTime = date.convertToDateTime(new Date());
  const minTime = useMemo(
    () => date.convertToDateTime(sub(new Date(), { days: 7 })),
    []
  );

  return (
    <>
      {!isViewOnly && type === 'patient' && (
        <NeuButtonToggle
          className="mt-5"
          style={{ paddingBottom: '8px' }}
          width="260px"
          value={day}
          onNeuChange={(e: any) => {
            setDay(e.detail.value);
          }}
        >
          <NeuButton value="today">Today</NeuButton>
          <NeuButton value="yesterday">Yesterday</NeuButton>
        </NeuButtonToggle>
      )}
      <div
        className="d-flex flex-column pl-8"
        style={{
          paddingBottom: '8px',
          width: 267
        }}
      >
        <div
          style={{
            width: 267,
            height: 22
          }}
        >
          <NeuLabel
            color="#191919"
            content={type === 'patient' ? 'Time' : 'Date & Time'}
            position="fixed"
            for="default"
          />
        </div>
        {type === 'patient' ? (
          <NeuInput
            type="time"
            autocomplete="off"
            inputmode="search"
            enterkeyhint="search"
            autocorrect="off"
            disabled={isViewOnly}
            error={isInvalidTime}
            value={selectedTime}
            onNeuChange={handleDateTimeChange}
            onNeuFocus={() => logTrackingEvent(logRoundProfileClick('date'))}
          />
        ) : (
          <NeuInput
            type="datetime-local"
            autocomplete="off"
            inputmode="search"
            enterkeyhint="search"
            autocorrect="off"
            max={maxTime}
            min={minTime}
            error={isInvalidDateTime}
            disabled={isViewOnly}
            style={{ minWidth: '17rem' }}
            value={selectedDateTime}
            onNeuChange={handleDateTimeChange}
            onNeuFocus={() => logTrackingEvent(logRoundProfileClick('date'))}
          />
        )}
        {!isViewOnly &&
          ((day === 'today' && format(new Date(), 'HH:mm') < selectedTime) ||
            selectedDateTime > maxTime) && (
            <span
              style={{
                color: '#CE3637',
                fontSize: 14,
                marginTop: 4,
                fontWeight: 'bold'
              }}
            >
              Time cannot be in the future
            </span>
          )}
      </div>
    </>
  );
};

export default RoundDateTimeSelect;

// Future use if change to :30 increments - pulled over from old round logic

// <Dropdown
//   style={{
//     width: 267,
//     height: 22,
//     flex: 1,
//     display: 'flex',
//     flexDirection: 'row'
//   }}
//   interface="popover"
//   onNeuChange={onChangeTime}
//   selectedText={timeStringList.current[selectedTimeIndex]}
// >
//   {timeStringList.current.map(time => {
//     return <NeuOption value={time}>{time}</NeuOption>;
//   })}
// </Dropdown>;

// useEffect(() => {
//   if (!currentTime.current) {
//     new Date().toLocaleTimeString();
//     currentTime.current = new Date();

//     timeStringList.current.push(
//       format(currentTime.current.setHours(0, 0, 0, 0), 'hh:mm a')
//     );
//     try {
//       for (
//         let nextTime = add(currentTime.current, { minutes: 30 });
//         compareAsc(nextTime, new Date()) === -1;
//         nextTime = add(nextTime, { minutes: 30 })
//       ) {
//         timeStringList.current.push(format(nextTime, 'hh:mm a'));
//       }
//       timeStringList.current.push(format(new Date(), 'hh:mm a'));
//       timeStringList.current.reverse();
//     } catch (err) {
//       console.log(`ERROR IS :${err}`);
//     }
//   }
// }, [masterTemplateList]);

// useEffect(() => {
//   if (currentTime.current) {
//     currentTime.current = format(new Date(), 'hh:mm');
//   }
// }, [masterTemplateList]);
